import PartnerBenefitsDecathlon from 'src/components/ClubComponents/PartnerBenefitsDecathlon'
import Seo from 'src/components/seo/Seo'

const ClubeDecathlonPartners = () => {
  return (
    <>
      <Seo title="Clube | Decathlon | Parceiros" />
      <PartnerBenefitsDecathlon />
    </>
  )
}

export default ClubeDecathlonPartners
